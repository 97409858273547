export class AddSlotRequest {
  doctorID: string;
  scheduledDate: string;
  hospitalID: number;
  day: string;
  weekCount: number;
}

export class ActiveDoctorsRequest {
  hospitalID: number;
}
