import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  setDetails(seo: { metaTitle: string; metaDesc: string }): void {
    this.title.setTitle(seo.metaTitle);

    this.meta.updateTag({
      name: 'description',
      content: seo.metaDesc,
    });

    this.meta.updateTag({
      name: 'og:title',
      content: seo.metaTitle,
    });

    this.meta.updateTag({
      name: 'og:description',
      content: seo.metaDesc,
    });
  }
}
